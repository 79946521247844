import {Component} from "react";
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {navigation, pages} from "../../assets/links";
import {Link} from "react-router-dom";

export default class Navbar extends Component {

  state = {
    isMobileMenuOpen: false,
  }

  constructor(props) {
    super(props);
    this.setMobileMenuOpen = this.setMobileMenuOpen.bind(this);
  }

  private setMobileMenuOpen(value: boolean) {
    this.setState({
      isMobileMenuOpen: value
    })
  }

  render() {
    return (
      <header className="absolute inset-x-0 top-0 z-50 font-medium text-slate-900 dark:text-white/95">
        <nav className="flex flex-row flex-nowrap items-center justify-between p-6 mx-auto" aria-label="Global">

          <div className="flex md:flex-initial pr-2">
            <Link to={pages.root.href} className="flex items-center space-x-2">
              <img className="h-8 w-8 rounded-full" src="/favicon.ico" alt=""/>
              <p className="text-lg font-bold text-slate-800 dark:text-slate-50">{pages.root.text}</p>
            </Link>
          </div>

          <div className="hidden md:flex md:gap-x-2 text-md">
            {Object.values(navigation.menu).map((item) => (
              <Link to={item.href} key={item.text} className="px-1.5 lg:px-4">
                {item.text}
              </Link>
            ))}
          </div>

          <div className="hidden md:flex pl-2">
            <Link to={pages.ws.href}
                  className="rounded-full px-4 py-1 text-white bg-lime-600 hover:bg-lime-700 dark:bg-lime-700 hover:dark:bg-lime-800">
              {pages.ws.text} <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>

          <div className="flex md:hidden">
            <button type="button"
                    className="-m-2.5 p-2.5 inline-flex items-center justify-center rounded-md"
                    onClick={() => this.setMobileMenuOpen(true)}>
              <Bars3Icon className="h-8 w-8" aria-hidden="true"/>
            </button>
          </div>

        </nav>

        <Dialog as="div" className="md:hidden" open={this.state.isMobileMenuOpen} onClose={this.setMobileMenuOpen}>
          <Dialog.Panel
            className="fixed shadow-lg inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white/60 dark:bg-slate-800/60 backdrop-blur-lg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">

            <div className="flex justify-between">

              <Link to={pages.root.href} className="flex items-center space-x-2" onClick={() => this.setMobileMenuOpen(false)}>
                <img className="h-8 w-8 rounded-full" src="/favicon.ico" alt=""/>
                <p className="text-lg font-bold text-slate-800 dark:text-slate-50">{pages.root.text}</p>
              </Link>

              <button type="button" className="rounded-md text-slate-800 dark:text-slate-50" onClick={() => this.setMobileMenuOpen(false)}>
                <XMarkIcon className="h-8 w-8" aria-hidden="true"/>
              </button>
            </div>

            <div className="mt-2 flow-root">
              <div className="py-6">
                {Object.values(navigation.menu).map((item) => (
                  <Link to={item.href} key={item.text} onClick={() => this.setMobileMenuOpen(false)}
                        className="-mx-3 px-3 py-2 block rounded-lg text-base font-bold leading-7 text-gray-900 dark:text-slate-50 hover:bg-slate-400/20 dark:hover:bg-slate-600/50 ">
                    {item.text}
                  </Link>
                ))}
              </div>

              <div className="py-2">
                <Link to={pages.ws.href} onClick={() => this.setMobileMenuOpen(false)}
                      className="py-2 block rounded-full text-center leading-7 text-white bg-lime-600 hover:bg-lime-700 dark:bg-lime-700 dark:hover:bg-lime-800">
                  {pages.ws.text} <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>

          </Dialog.Panel>
        </Dialog>

      </header>
    )
  }
}
