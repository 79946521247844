import {Component} from "react";

interface Props {
  text?: string;
}

export default class BlockQuote extends Component<Props, {}> {
  render() {
    return (
      <blockquote className="mt-6 flex">
        <svg aria-hidden="true" className="h-10 text-gray-400/50" viewBox="0 0 24 27">
          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
        </svg>
        <p className="pl-4 text-xl italic font-serif">{this.props.text}</p>
      </blockquote>
    )
  }
}