import {isProd} from "./logic";

export const pages = {
  root: {href: '/', text: 'Openize'},
  ws: {href: isProd ? 'https://ws.openize.io' : 'https://localhost:3001', text: 'Workspace'},
  tools: {href: '/tools', text: 'Tools'},
  methodology: {href: '/methodology', text: 'Methodology'},
  projects: {href: '/projects', text: 'Projects'},
  blog: {href: '/blog', text: 'Blog'},
  about: {href: '/company/about', text: 'About us'},
  ask: {href: 'https://forms.gle/NaRtvMFwf16waySdA', text: 'Ask a question'},
  privacy: {href: '/company/privacy-policy', text: 'Privacy policy'},
  terms: {href: '/company/terms-of-use', text: 'Terms of use'},
}

export const tools = [
  {href: '/tools/nest', text: 'Nest Framework'},
]

export const social = {
  twitter: {href: 'https://twitter.com/openize_io', text: 'Twitter', iconClass: 'fab fa-twitter'},
  facebook: {href: 'https://facebook.com/openize.io', text: 'Facebook', iconClass: 'fab fa-facebook'},
  telegram: {href: 'https://t.me/openize_io', text: 'Telegram', iconClass: 'fab fa-telegram'},
  rss: {href: '/rss', text: 'RSS feed', iconClass: 'fa fa-rss'},
}

export const navigation = {

  menu: {
    tools: pages.tools,
    methodology: pages.methodology,
    projects: pages.projects,
    blog: pages.blog,
    about: pages.about,
  },

  hero: {
    primary: {href: pages.ws.href, text: 'Continue to workspace', textSm: 'Workspace'},
    secondary: {href: pages.tools.href, text: 'Learn more about our tools', textSm: 'Explore tools'},
  },

  footer: {
    openize: {
      text: pages.root.text,
      items: [
        {href: pages.root.href, text: 'Home'},
        pages.about,
        pages.ws,
      ]
    },
    social: {
      text: 'Stay tuned',
      items: [
        social.twitter,
        social.facebook,
        social.rss
      ],
    },
    help: {
      text: 'Help',
      items: [
        pages.terms,
        pages.privacy,
        pages.ask,
      ]
    }
  },

  comingsoon: {
    social: {
      items: [
        social.twitter,
        social.facebook,
        social.telegram,
      ]
    },
  },

}
