import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {

  useLocation();

  const everChanging = Math.random();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [everChanging]);

  return null;
}