import {Component} from "react";

export default class NotFoundPage extends Component {

  render() {
    return (
      <div className="mx-auto max-w-2xl pt-32 md:pt-36 px-6 pb-14 md:pb-18">

        <h1 className="text-9xl font-black tracking-tight text-slate-200/80 dark:text-slate-700/80">404</h1>
        <h1 className="text-4xl md:text-5xl font-extrabold tracking-tight text-slate-800 dark:text-slate-200">Not found</h1>
        <p className="text-lime-600">{window.location.pathname}</p>
        <p className="mt-6 text-md sm:text-lg text-gray-600 dark:text-slate-200">This page is not found.</p>

      </div>
    )
  }
}

