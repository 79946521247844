import {Component} from "react";
import {texts} from "../assets/texts";
import {Link} from "react-router-dom";
import {navigation} from "../assets/links";
import Background, {Style} from "../components/decoration/Background";
import HeaderSpace from "../components/elements/HeaderSpace";

export default class HomePage extends Component {
  render() {
    return (
      <>
        <Background styles={[Style.AMBIENT, Style.SUNKEN_BOTTOM]}>

          <HeaderSpace/>

          <div className="mx-auto max-w-2xl py-10 md:py-14 px-6 text-slate-900 dark:text-slate-200">
            <h1 className="text-4xl md:text-5xl font-extrabold tracking-tight ">{texts.hero.title}</h1>
            <p className="mt-6 text-md sm:text-lg">{texts.hero.text}</p>
            <div className="mt-10 flex items-center justify-start gap-x-6">
              <Link to={navigation.hero.primary.href} className="text-lg whitespace-nowrap text-white/95 rounded-full px-5 py-3 font-semibold bg-lime-600 hover:bg-lime-700 dark:bg-lime-700 dark:hover:bg-lime-800">
                <span className="hidden sm:block">{navigation.hero.primary.text}<span aria-hidden="true"> &rarr;</span></span>
                <span className="block sm:hidden">{navigation.hero.primary.textSm}<span aria-hidden="true"> &rarr;</span></span>
              </Link>
              <Link to={navigation.hero.secondary.href} className="text-lg whitespace-nowrap font-semibold leading-6">
                <span className="hidden sm:block">{navigation.hero.secondary.text}<span aria-hidden="true"> &rarr;</span></span>
                <span className="clock sm:hidden">{navigation.hero.secondary.textSm}<span aria-hidden="true"> &rarr;</span></span>
              </Link>
            </div>
          </div>
        </Background>

      </>
    );
  }
}