import {Component, PropsWithChildren} from "react";

export default class HeaderSpace extends Component<PropsWithChildren> {
  render() {
    return (
      < div className="pt-20">
        {this.props.children}
      </div>
    );
  }
}

