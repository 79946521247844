import {Component} from "react";
import {navigation} from "../../assets/links";
import {texts} from "../../assets/texts";
import {Link} from "react-router-dom";

export default class Footer extends Component {

  render() {
    return (
      <footer className="text-base">
        <section className="bg-slate-700 py-4">
          <div
            className="mx-auto px-6 pt-2 md:pt-6 pb-4 max-w-2xl grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-3 text-slate-400">
            <div className="flex flex-row flex-wrap justify-center md:justify-start md:flex-col gap-2">
              <div className="font-bold text-slate-300 pb-1 hidden md:flex">{navigation.footer.openize.text}</div>
              {navigation.footer.openize.items.map((item, idx, array) => [
                <Link to={item.href} key={`${item.href}-1`}>{item.text}</Link>,
                (idx < array.length - 1) ? <span key={`${item.href}-2`} className="flex md:hidden">{texts.footerSeparator}</span> : null
              ])}
            </div>

            <div className="flex flex-row flex-wrap justify-center md:justify-start md:flex-col gap-2 gap-x-4">
              <div className="font-bold text-slate-300 pb-1 hidden md:flex">{navigation.footer.social.text}</div>
              {navigation.footer.social.items.map((item) => (
                <Link key={`${item.href}`} to={item.href} target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
                  <i className={'w-4 h-4 text-blue-400 ' + item.iconClass}/> {item.text}
                </Link>
              ))}
            </div>

            <div className="flex flex-row flex-wrap justify-center md:justify-start md:flex-col gap-2">
              <div className="font-bold text-slate-300 pb-1 hidden md:flex">Help</div>
              {navigation.footer.help.items.map((item, idx, array) => [
                <Link key={`${item.href}-1`} to={item.href} target="_blank" rel="noopener noreferrer" >{item.text}</Link>,
                (idx < array.length - 1) ? <span key={`${item.href}-2`} className="flex md:hidden">{texts.footerSeparator}</span> : null
              ])}
            </div>

          </div>
        </section>

        <section className="bg-slate-800">
          <div className="mx-auto text-center px-6 pt-2 pb-2 max-w-2xl text-slate-400">
            <p>© 2023 Openize. All rights reserved.</p>
          </div>
        </section>

      </footer>
    )
  }
}
