import {Component} from "react";

interface Props {
  margin?: number;
}

export default class HorizontalLine extends Component<Props> {
  render() {
    return (
      <hr className={`my-${this.props.margin ?? 0} h-px border-gray-200 dark:border-gray-500/50`}/>
    )
  }

}