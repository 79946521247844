import {Component} from "react";
import {navigation} from "../assets/links";

export default class ComingSoonPage extends Component {
  render() {
    return (
      <div className="dark:bg-slate-800 flex flex-col landscape:flex-row landscape:space-x-10 justify-center items-center h-screen border pb-10 space-y-6 font-light text-slate-600 text-center ">
        <div className="w-40 sm:w-56 h-40 sm:h-56 rounded-full" style={{backgroundImage: `url('/logo-192.png')`, backgroundSize: 'cover'}}></div>
        <div className="space-y-6">
          <>
            <p className="text-3xl sm:text-4xl sm:scale-110">Launching in</p>
            <p className="text-7xl sm:text-8xl">2024</p>
          </>
          <div className="space-x-8 sm:space-x-14">
            {navigation.comingsoon.social.items.map((item) => (
              <a href={item.href} key={`${item.href}`} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass + ' fa-2x text-blue-400 hover:text-blue-500'}/>
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }

}