import {Component, PropsWithChildren} from "react";

interface IStyle {
  classNames?: string,
}

export const Style = {
  CLEAN: {
    classNames: 'bg-white dark:bg-slate-800'
  },
  AMBIENT: {
    classNames: "bg-slate-50 dark:bg-slate-700 text-slate-900 dark:text-slate-200",
  },
  ACCENT: {
    classNames: "bg-slate-200 dark:bg-slate-600 text-slate-900 dark:text-slate-200",
  },
  SUNKEN_TOP: {
    classNames: "bg-gradient-to-b " +
      "from-0%      via-[8px]      to-[16px]      from-[#b6c1cd]      via-[#cfd6dd]/50      to-write/0 " +
      "dark:from-0% dark:via-[8px] dark:to-[20px] dark:from-slate-950/80 dark:via-slate-900/50 dark:to-write/0 ",
  },
  SUNKEN_BOTTOM: {
    classNames: "" +
      "bg-gradient-to-t " +
      "from-0%      via-[6px]      to-[16px]      from-[#b6c1cd]/70      via-[#cfd6dd]/40      to-white/0      " +
      "dark:from-0% dark:via-[8px] dark:to-[20px] dark:from-slate-950/70 dark:via-slate-900/40 dark:to-white/0 "
  },
}

interface Props extends PropsWithChildren {
  className?: string,
  styles?: IStyle[];
}

export default class Background extends Component<Props> {

  render() {
    const styles: IStyle[] = this.props.styles ?? [Style.CLEAN]
    const classNames = `${this.props.className ?? ''} ${styles.map(_ => _.classNames).filter(_ => _).join(' ')}`;
    return (
      <div className={classNames}>{this.props.children}</div>
    )
  }

}