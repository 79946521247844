import {Component} from "react";
import {texts} from "../assets/texts";
import HorizontalLine from "../components/elements/HorizontalLine";

export default class ProjectsPage extends Component {

  render() {
    return (<>
        <div className="px-6 pt-28 md:pt-36 mx-auto max-w-2xl pb-14 md:pb-18 text-slate-800 dark:text-slate-200">
          <h1 className="text-4xl sm:text-5xl font-extrabold tracking-tight">{texts.projects.title}</h1>
        </div>

        <HorizontalLine/>
      </>
    )
  }
}

