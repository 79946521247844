import './assets/index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {pages} from "./assets/links";
import ReactDOM from "react-dom/client";
import ReactGA from 'react-ga4';
import Footer from "./components/global/Footer";
import Navbar from "./components/global/Navbar";
import ScrollToTop from "./components/behaviour/ScrollToTop";
import {InDev, InProd} from "./components/behaviour/Env";
import ComingSoonPage from "./pages/ComingSoonPage";
import HomePage from "./pages/HomePage";
import ToolsPage from "./pages/ToolsPage";
import MethodologyPage from "./pages/MethodologyPage";
import CompanyPage from "./pages/CompanyPage";
import UnderConstructionPage from "./pages/UnderConstructionPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProjectsPage from "./pages/ProjectsPage";

ReactGA.initialize('G-7LLTJPVR2J');
ReactGA.send('pageview');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <InProd>
      <ComingSoonPage/>
    </InProd>

    <InDev>
      <BrowserRouter>
        <ScrollToTop/>

        <Navbar/>

        <Routes>
          <Route path={pages.root.href} element={<HomePage/>}/>
          <Route path={pages.tools.href} element={<ToolsPage/>}/>
          <Route path={pages.methodology.href} element={<MethodologyPage/>}/>
          <Route path={pages.projects.href} element={<ProjectsPage/>}/>
          <Route path={pages.blog.href} element={<UnderConstructionPage/>}/>
          <Route path={pages.about.href} element={<CompanyPage/>}/>
          <Route path={pages.ws.href} element={<UnderConstructionPage/>}/>
          <Route path={pages.terms.href} element={<UnderConstructionPage/>}/>
          <Route path={pages.privacy.href} element={<UnderConstructionPage/>}/>
          <Route path={pages.ask.href} element={<UnderConstructionPage/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>

        <Footer/>

      </BrowserRouter>
    </InDev>
  </React.StrictMode>,
);