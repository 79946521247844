import {Component} from "react";
import {texts} from "../assets/texts";
import HorizontalLine from "../components/elements/HorizontalLine";
import BlockQuote from "../components/elements/BlockQuote";

export default class MethodologyPage extends Component {

  render() {
    return (
      <div className="px-6 pt-28 md:pt-36 mx-auto max-w-2xl pb-14 md:pb-18 text-slate-800 dark:text-slate-200">

        <h1 className="text-4xl sm:text-5xl font-extrabold tracking-tight">{texts.methodology.title}</h1>

        <div className="mt-10 text-md sm:text-lg">
          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[1].title}</h1>
          <p className="mt-6">{texts.methodology.sections[1].texts[1]}</p>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[2].title}</h1>
          <p className="mt-6">{texts.methodology.sections[2].texts[1]}</p>
          <p className="mt-6">{texts.methodology.sections[2].texts[2]}</p>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[3].title}</h1>
          <p className="mt-6 font-bold">{texts.methodology.sections[3].texts[1]}</p>
          <p className="mt-6">{texts.methodology.sections[3].texts[2]}</p>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[4].title}</h1>
          <p className="mt-6">{texts.methodology.sections[4].texts[1]}</p>
          <BlockQuote text={texts.methodology.sections[4].texts[2]}/>
          <p className="mt-6">{texts.methodology.sections[4].texts[3]}</p>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[5].title}</h1>
          <p className="mt-6 font-bold">{texts.methodology.sections[5].texts[1]}</p>
          <p className="mt-6">{texts.methodology.sections[5].texts[2]}</p>
          <p className="mt-6">{texts.methodology.sections[5].texts[3]}</p>
          <ol className="list-decimal list-inside">
            {Object.values(texts.methodology.sections[5].texts[4]).map((item, idx) => (
              <li key={idx} className="list-item pt-4 font-bold">{item.bullet}
                <p className="sm:pl-6 font-normal">{item.text}</p>
              </li>
            ))}
          </ol>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[6].title}</h1>
          <p className="mt-6">{texts.methodology.sections[6].texts[1]}</p>
          <p className="mt-6">{texts.methodology.sections[6].texts[2]}</p>
          <p className="mt-6">{texts.methodology.sections[6].texts[3]}</p>
          <HorizontalLine margin={6}/>

          <h1 className="mt-6 text-2xl sm:text-3xl font-extrabold">{texts.methodology.sections[7].title}</h1>
          <p className="mt-6">{texts.methodology.sections[7].texts[1]}</p>
          <HorizontalLine margin={6}/>

        </div>

      </div>
    )
  }
}