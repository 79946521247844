import {Component, PropsWithChildren} from "react";
import {isDev, isProd} from "../../assets/logic";

interface Props extends PropsWithChildren {
  env: 'development' | 'production';
}

class InEnv extends Component<Props> {

  render() {
    return (
      (this.props.env === 'production' && isProd) || (this.props.env === 'development' && isDev) ? <>{this.props.children}</> : null
    );
  }
}

class InDev extends Component<PropsWithChildren> {
  render() {
    return (<InEnv env='development'>{this.props.children}</InEnv>);
  }
}

class InProd extends Component<PropsWithChildren> {
  render() {
    return (<InEnv env='production'>{this.props.children}</InEnv>);
  }
}

export {InDev, InProd};

