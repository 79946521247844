export const texts = {

  footerSeparator: '•',

  hero: {
    title: 'Manage complexity at scale',
    text:
      'Openize creates tools for sustainable development of complex software systems. ' +
      'Our tools streamline feature and workflow management, ' +
      'deliver actionable insights into the technical debt, ' +
      'enable rapid prototyping and more. ' +
      'Get started with Openize technology by opening your private workspace. '
  },

  tools: {
    title: 'Tools',
    sections: {}
  },

  projects: {
    title: 'Projects',
    sections: {}
  },

  methodology: {
    title: 'Methodology',
    sections: {
      1: {
        title: '1. Who is Openize\'s customer?',
        texts: {
          1: 'Any team that develops complex enterprise software is our customer.'
        }
      },

      2: {
        title: '2. What is the customer problem?',
        texts: {
          1:
            'To stay competitive, technology enterprises need to expand. ' +
            'As they grow, the task of maintaining the underlying business software becomes increasingly challenging, and this is particularly evident in large technology clusters with numerous software systems. ' +
            'The existing business processes become interdependent and overlap in various ways. ' +
            'Different engineering teams employ a diverse range of programming languages, frameworks, CI/CD approaches, and orchestration technologies. ' +
            'Each day, these teams face the growing intrinsic complexity of their systems. ',
          2:
            'While engineering leads strive to find effective ways to coordinate teamwork and regain control, business owners start noticing the rising costs associated with expanding their operations.'
        }
      },

      3: {
        title: '3. Symptoms',
        texts: {
          1:
            'Business expansions are becoming less efficient.',
          2:
            'To launch a new workflow, or enable an existing workflow in a new segment, teams have to dive deep into the business processes and identify all the affected parts, then learn how to reconfigure these parts, and then deploy their changes in multiple pipelines for testing. ' +
            'Today, many companies still carry out this painstaking and repetitive effort manually, therefore it is time-consuming, error-prone and is the main negative factor for the overall expansion efficiency.'
        }
      },

      4: {
        title: '4. Dive deep',
        texts: {
          1:
            'We spoke to multiple engineering teams and discussed the problem in detail. Most often what we heard was:',
          2:
            'We’d be happy if expansions were doable by non-technical users with no prior experience.',
          3:
            'This pinpoints the problem. ' +
            'In a world of ever-changing workflows, any domain knowledge inevitably expires, whether oral or written, tribal or properly recorded. ' +
            'So, the teams need a tool that is smart enough that even a user with outdated or initially insufficient domain knowledge can still make a good use of it. ' +
            'A tool that can get the job done while teaching the user how exactly it needs to be done. ' +
            'Somewhat a hybrid of a control panel and a knowledge base.',
        }
      },

      5: {
        title: '5. Root Cause',
        texts: {
          1:
            'Businesses lack a unified method for feature management.',
          2:
            'Indeed, automating feature launches requires a common method of working with features in principle. ' +
            'Throughout the history of enterprise software, various engineering teams have made efforts to automate their repetitive tasks, however, the world still doesn\'t have a framework for describing, creating and managing business features in general. ' +
            'Developers do not yet have a formal definition of a business feature per se, and without that they cannot start working with it programmatically. ' +
            'Each technology team in every company does their best to improve their expansions – and yet, with each launch, engineers have to rediscover the answers to the same questions: “Which system is this feature implemented in?” “How does this feature work?” “How do I configure it?” “Where is the source code and documentation?” ',
          3:
            'In the context of the expansion inefficiency problem, we highlight the following problems as the root cause:',
          4: {
            1: {
              bullet: 'Lack of feature discoverability',
              text:
                'Given a system, engineers have no ability to quickly discover all the features in it: there is no feature knowledge base, nor a platform to create one. ' +
                'Given a feature, developers cannot see how it affects other features: there is no visibility into the feature relationships. ' +
                'Given a particular configuration, engineers cannot determine exactly what features it controls: there is no visibility into the relationships between features and configurations.'
            },
            2: {
              bullet: 'Lack of unified feature documentation',
              text:
                'Given a feature, engineers want to learn how it works internally, but there is no easy way to find the corresponding source code, pipeline, infrastructure or documentation. ' +
                'It is also not clear how to get the necessary permissions to deploy the feature, and how to test it.'
            },
            3: {
              bullet: 'Lack of unified feature control',
              text:
                'Given a feature, engineers struggle to discover how to enable, disable or configure it: each feature is controlled in its own way, requiring time to study.'
            },
          },
        },
      },

      6: {
        title: '6. Prognosis',
        texts: {
          1:
            'We believe that the problem of inefficient expansions will not disappear by itself and will still be relevant in years to come.',
          2:
            'The first reason to think so is the constant growth of business complexity. ' +
            'With each expansion, teams are adding new segment-specific features or entire workflows, increasing the number of configurations and testing paths. ' +
            'Consequentially, expansions will tend to slow down, eating up even more resources.',
          3:
            'The second reason is the constant growth of technological diversity. ' +
            'Business software can be really diverse. ' +
            'There are single-app solutions maintained by one team, and large ecosystems with hundreds of apps shared by dozens of teams. ' +
            'There is a vast number of customer-facing solutions and a decent pile of internal tools. ' +
            'Different engineering teams use different programming languages, frameworks and approaches. ' +
            'More and more technology clusters are switching from batch jobs to transactional processing. ' +
            'Deprecated solutions are replaced by new ones de jure and continue to be used de facto (besides, the very process of deprecation is a considerable effort). ' +
            'Technically, two arbitrary software systems already have very little in common, and that fact does not contribute to smooth business expansions.',
        },
      },

      7: {
        title: '7. Solution',
        texts: {
          1:
            'We studied the software solutions available on the market, and concluded that none of them offer intuitive feature management.'
        }
      }
    },
  },

  company: {
    about: {
      title: 'About us',
      text:
        'Openize is a software development company that creates advanced tools for technology-driven enterprises to manage their growth. '
    }
  },

}

// 900
// 'Openize develops advanced software tools to support the growth of your technology-driven enterprise. ' +
// 'Instrument your business logic at any granularity with our elegant, language-agnostic framework. ' +
// 'Monitor the instrumented workflows to gain actionable insights. ' +
// 'Roll out new features in seconds, regardless of their complexity. ' +
// 'Stay the best by harnessing unparalleled business observability, adaptability and control. '

// 801
// 'To succeed and grow, technology-driven companies need to embrace automation. ' +
// 'However, large and complex automated systems can eventually hinder business expansion. ' +
// +'<br/><br/>' +
// 'Openize\'s mission is to lead the way in the next frontier of digital transformation: autonomous systems. ' +
// 'Our vision is a future where intelligent, self-governed software processes can propose and enact business decisions, unlocking human potential and driving open innovation. '

// 800
// 'Automation is essential for technology-driven businesses to become successful, as it reduces effort, saves time, and enables strategic focus. ' +
// 'To make informed strategic decisions you need two more things: a deep understanding of current business operations and the ability to quickly adapt to changing customer needs, market trends, and competition. ' +
// '<br/><br/>' +
// 'At Openize, we develop advanced tools that are designed to solve both problemsand data mining, helping businesses reach full automation and go beyond. ' +
// 'Our mission is to open the door to the next frontier of digital transformation: <i>autonomous</i> operation, in which business decisions are proposed and enacted by intelligent, self-governing software processes. '

// 700
// 'Openize creates software tools for automation and complexity management. ' +
// 'Our advanced instruments allow engineers to streamline complex workflows, ' +
// 'roll out new features in seconds, ' +
// 'and achieve an unprecedented level of business observability and control. '


// 600
// 'Openize is a software company that develops advanced tools for managing complexity in large software systems. ' +
// 'Our solutions help technology-driven businesses to address challenges such as the increased effort to maintain legacy code, slowdowns from technical debt or inefficient cross-team collaboration. ' +
// 'With Openize\'s tools, engineering teams can reduce effort and improve efficiency while gaining valuable business insights to support better decision-making. '

// 500
// 'Openize develops advanced tools for managing complexity in large software systems. ' +
// 'Our tools allow engineers to automatically discover the legacy workflows, ' +
// reduce technical debt ' +
// and streamline cross-team collaboration while gaining valuable business insights. '

// 400
// 'At Openize, we develop advanced tools to help businesses fully automate and prepare for transitioning to <i>autonomous</i> operation, ' +
// 'a next phase of digital transformation where decision-making can be made by self-governing software processes. '

// 300
// 'Openize offers a new holistic approach to managing complex business domains. ' +
// 'With our framework, customers can consistently roll out new features across large business clusters of hundreds of apps. ' +
// 'Our customers get deep insights into how each "if-else" block works, ' +
// 'users can make informed decisions, identify areas for improvement, and toggle features in runtime in a few clicks.'


// tagline: 'Software solutions for software problems',
// tagline: 'Self-controlling systems',
// tagline: 'From automated to autonomous',
// tagline: 'Next level of controlling processes',
// tagline: 'Instrument your code, control it in runtime',
// tagline: 'Manage systems of any size',
// tagline: 'Go complex. It\'s fun',
// tagline: 'Treasuring legacy. Embracing complexity',
// tagline: 'Complexity under control',
// tagline: 'When legacy becomes a burden',
// tagline: 'Legacy is priceless',
// tagline: 'Legacy code will never be a burden again',
// tagline: 'Master your apps inside out',
// tagline: 'Unlock the power of your existing apps',
// tagline: 'Managing business with technology',
// tagline: 'See inside the business',
// tagline: 'When a business grows uncontrollably',
// tagline: 'Scale your business with confidence',
// tagline: 'Open-source tools for managing complexity',
// tagline: 'Open up to simplicity',
// tagline: 'Mastering complexity',
// tagline: 'Embrace complexity',
// tagline: 'Nest – when complexity needs to be tamed',
// tagline: 'Open-source tools for managing complexity',
// tagline: 'Open-source framework for feature management',
// tagline: 'Customer-hosted feature management platform',
